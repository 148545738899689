<template>

    <v-container class="white-bg mt-48px ct-mw">
       <v-row justify="center" class="text-center">
        <h4 class="navy-text">CORE ASSESSMENT</h4>
      </v-row>
       <v-row justify="center" class="text-center">
        <span class="base-text">แบบประเมินเพื่อค้นหาศักยภาพของตัวคุณ</span>
      </v-row>
      <v-divider></v-divider>
      <div v-if="step === 1">
        <v-row justify="center">
          <v-container>
            <v-row justify="center">
              <span>
                โปรดกรอกข้อมูลตามความจริง
              </span>
            </v-row>
            <v-row>
              <v-form
                v-model="form1"
                ref="form"
                lazy-validation
              >
                 <v-radio-group
                  v-model="sex"
                  label="เพศ*: "
                  :rules="required"
                  required
                >
                  <v-radio
                    label="ชาย"
                    value="ชาย"
                  ></v-radio>
                  <v-radio
                    label="หญิง"
                    value="หญิง"
                  ></v-radio>
                  <v-radio
                    label="ไม่ระบุเพศ"
                    value="ไม่ระบุเพศ"
                  ></v-radio>
                </v-radio-group>

                <v-radio-group
                  v-model="age"
                  label="ช่วงอายุ*: "
                  :rules="required"
                  required
                >
                  <v-radio
                    v-for="(a, index) in ageList"
                    v-bind:key="index"
                    :value="a"
                    :label="a"
                  ></v-radio>
                </v-radio-group>
              </v-form>
            </v-row>
             <v-row justify="center">
              <v-btn
                :disabled="!(form1 && age && sex)"
                color="error"
                @click="nextStep(2)"
              >next</v-btn>
            </v-row>
          </v-container>
        </v-row>
      </div>
      <div v-if="step === 2">
         <v-row justify="center" class="py-4">
          <span >โปรดตอบสิ่งที่ใกล้เคียงคุณมากที่สุด มิเช่นนั้นผลลัพธ์ที่ออกมาจะไม่ชัดเจน</span>
        </v-row>
        <v-row v-for="q in pageQuestion" v-bind:key="q.no">
         <v-col cols="12">
          <v-row>
            <span class="head-ch font-weight-bold">{{q.no}}. {{q.qTh}}</span>
            <v-container class="pa-0">
              <v-radio-group
                v-model="answer[q.no-1]"
                row
              >
                <v-col
                  v-for="(n, index) in choise" :key="index"
                  cols="2"
                >
                  <v-row>
                    <v-radio
                    :value="n.point"
                    :color="n.styleColor"
                    ></v-radio>
                    <span :class="n.style">{{n.name}}</span>
                  </v-row>
                </v-col>
              </v-radio-group>
            </v-container>
          </v-row>
          <v-divider></v-divider>
         </v-col>
        </v-row>

        <v-row justify="center" class="py-2">
          {{page}} / {{questions.length / eachPage}}
        </v-row>

        <v-row justify="center" v-if="!finish">
          <v-btn
            :disabled="!canNext"
            color="error"
            @click="next()"
          >next</v-btn>
          <!-- <v-pagination
            v-model="page"
            :length="questions.length/eachPage"
            @input="$vuetify.goTo(number, options)"
            circle
          ></v-pagination> -->
        </v-row>
        <v-row justify="center" class="py-4" v-if="finish">
          <v-btn
            :disabled="questCom"
            color="error"
            @click="nextStep(3)"
          >submit</v-btn>
        </v-row>

        <!-- test -->
        <!-- <v-row justify="center" class="py-4">
          <v-btn
            color="error"
            @click="nextStep(3)"
          >submit</v-btn>
        </v-row> -->

      </div>
      <div v-if="step === 3">
        <v-row justify="center">
          <v-col md="8">
            <v-container>
              <v-form
                v-model="form2"
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="email"
                  label="Email"
                  :rules="emailRules"
                  required
                ></v-text-field>
                <v-text-field
                  v-model="tel"
                  label="Tel"
                  :rules="telRules"
                  type="number"
                  required
                ></v-text-field>
                <v-select
                  v-model="positionLevel"
                  :items="positionLevelList"
                  label="ระดับตำแหน่งงาน"
                  :rules="required"
                  required
                ></v-select>
                <v-text-field
                  v-model="position"
                  label="ตำแหน่งงาน"
                  :rules="required"
                  required
                ></v-text-field>
                <div>
                  <v-checkbox
                    v-model="checkBox"
                    label="ข้าพเจ้ายอมรับ <router-link to='/termofservice' target='_blank'>
                        ข้อกำหนดในการให้บริการ</router-link>
                    และได้ตรวจสอบข้อมูลอย่างถูกต้องทุกประการแล้ว"
                    required
                  >
                  <template v-slot:label>
                    <div>
                      ข้าพเจ้ายอมรับ
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <a
                            target="_blank"
                            href="/termofservice"
                            @click.stop
                            v-on="on"
                          >
                            ข้อกำหนดในการให้บริการ
                          </a>
                        </template>
                        Opens in new tab
                      </v-tooltip>
                      และได้ตรวจสอบข้อมูลอย่างถูกต้องทุกประการแล้ว
                    </div>
                  </template>
                  </v-checkbox>
                </div>
                <v-row justify="center" class="py-4">
                  <v-btn
                    :disabled="!(form2 && checkBox)"
                    color="error"
                    @click="nextStep(4)"
                  >get result</v-btn>
                </v-row>
              </v-form>
            </v-container>
          </v-col>
        </v-row>
      </div>
    </v-container>

</template>

<script>
import * as easings from 'vuetify/es5/services/goto/easing-patterns';
import serveryService from '../../firebase/firestore/survey';
import fieldValue from '../../firebase/firestore/fieldValue';

export default {
  name: 'question',
  data() {
    return {
      surveyId: 'core',
      form1: false,
      form2: false,
      finish: false,
      docId: '',
      startTime: '',
      age: '',
      ageList: [
        'อายุต่ำกว่า 20 ปี',
        'อายุ 20 - 25 ปี',
        'อายุ 26 - 30 ปี',
        'อายุ 31 - 35 ปี',
        'อายุ 35 ปีขึ้นไป',
      ],
      questions: [],
      answer: [],
      // answer: [1, 0, 0, 0, 1, 1, 1, 0, 1, 0, 1, 1, 0, 0, 0, 1, 1,
      //   1, 0, 1, 1, 0, 1, 1, 0, 0, 1, 0, 0, 1, 1, 1, 0, 0, 0, 1, 1, 1, 0, 0],
      score: {},
      step: 1,
      page: 1,
      sex: '',
      email: '',
      tel: '',
      position: '',
      positionLevel: '',
      positionLevelList: [
        'Staff',
        'Senior Staff',
        'Supervisor',
        'Manager',
        'Executive',
      ],
      checkBox: false,
      currPro: 0,
      eachPage: 10,
      maxPage: 0,
      canNext: false,
      questCom: true,
      pageQuestion: [],
      choise: [
        {
          point: 1,
          name: 'YES',
          show: true,
          style: 'navy-text font-weight-black',
          styleColor: '#003A70',
        },
        {
          point: 0,
          name: 'NO',
          show: true,
          style: 'red-text font-weight-black',
          styleColor: '#F5333F',
        },
      ],
      choiseR: [0, 1],
      required: [value => !!value || 'Required.'],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      telRules: [value => value.length >= 9 || 'Tel is required.'],
      number: 0,
      easings: Object.keys(easings),
      options: {
        duration: 300,
        easing: 'easeInOutCubic',
        offset: 250,
      },
    };
  },
  watch: {
    page(newVal) {
      this.changePage(newVal);
    },
    answer(newVal) {
      const cur = newVal.filter(i => i === 1 || i === 0);
      this.currPro = cur.length;
      if (this.currPro === this.questions.length) this.questCom = false;
      if (this.currPro === this.page * this.eachPage) this.canNext = true;
    },
  },
  methods: {
    async nextStep(next) {
      if (this.step === 1 && this.$refs.form.validate()) {
        this.step = next;
      } else if (this.step === 2 && (this.currPro === this.questions.length)) {
        this.record();
        this.step = next;
      } else if (this.step === 3 && this.$refs.form.validate()) {
        await this.updataData();
        this.$router.push({ path: '/survey/result', query: { score: this.score, sex: this.sex } });
      }
    },
    changePage(page) {
      const endPoint = (this.eachPage * page);
      const startPoint = endPoint - this.eachPage;
      this.pageQuestion = this.questions.slice(startPoint, endPoint);
    },
    next() {
      this.page += 1;
      if (this.page >= this.maxPage) {
        this.page = this.maxPage;
        this.finish = true;
      }
      this.$vuetify.goTo(this.number, this.options);
      this.canNext = false;
    },
    async record() {
      const { uid } = this.$store.getters.user ? this.$store.getters.user : { uid: null };
      this.calculateScore();
      const data = {
        uid,
        surveyId: this.surveyId,
        sex: this.sex,
        age: this.age,
        score: this.score,
        startTime: this.startTime,
        endTime: fieldValue.serverTimestamp(),
      };
      const res = await serveryService.setAnswer(data);
      this.docId = res.id;
    },
    calculateScore() {
      this.score = {
        C: 0,
        O: 0,
        R: 0,
        E: 0,
      };
      this.questions.forEach((q, index) => {
        if (!q.reverse) {
          this.score[q.type] += this.answer[index];
        } else {
          this.score[q.type] += this.choiseR[this.answer[index]];
        }
      });
    },
    async updataData() {
      const data = {
        email: this.email,
        tel: this.tel,
        position: this.position,
        positionLevel: this.positionLevel,
      };
      await serveryService.updateAnswer(this.docId, data);
    },
  },
  async mounted() {
    const data = await serveryService.getQuestion(this.surveyId);
    this.startTime = serveryService.fbTime();
    this.questions = data.question;
    this.maxPage = this.questions.length / this.eachPage;
    this.changePage(this.page);
  },
};
</script>

<style lang="sass" scoped>
.bule-col
  color: 0069CC
.red-col
  color: F98A92
.head-ch
  font-size: 1rem
  @media screen and ( min-width: 600px)
    font-size: 1.25rem
.text-ch
  font-size: 0.875rem
.big-radio
  width: 2em
  height: 2em
  align-self: center
  @media screen and ( min-width: 600px)
    width: 3em
    height: 3em
    border: 3em
.medium-radio
  width: 1.5em
  height: 1.5em
  align-self: center
  @media screen and ( min-width: 600px)
    width: 2em
    height: 2em
    border: 2em
.height-ch
  height: 50px
.small-radio
  width: 1.2em
  height: 1.2em
  @media screen and ( min-width: 600px)
</style>
