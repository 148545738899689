import firebase from '../index';

const increment = val => firebase.firestore.FieldValue.increment(val);

const arrayUnion = val => firebase.firestore.FieldValue.arrayUnion(val);

const arrayRemove = val => firebase.firestore.FieldValue.arrayRemove(val);

const serverTimestamp = () => firebase.firestore.FieldValue.serverTimestamp();

const deleteField = () => firebase.firestore.FieldValue.delete();

export default {
  increment,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  deleteField,
};
