import firebase from '../index';

const quest = firebase.firestore().collection('surveyQuestion');
const ans = firebase.firestore().collection('surveyAnswer');
const fbTime = () => firebase.firestore.Timestamp.now();

const getQuestion = async (questionName) => {
  const snapshot = await quest.doc(questionName).get();
  return snapshot.data();
};

const setAnswer = data => ans.add(data);

const getAnswer = async (docId) => {
  const snapshot = await quest.doc(docId).get();
  return snapshot.data();
};

const updateAnswer = (docId, data) => ans.doc(docId).update(data);

export default {
  getQuestion,
  getAnswer,
  setAnswer,
  updateAnswer,
  fbTime,
};
